import React, { useRef, useState } from 'react';
import { Button, makeStyles, FormControl, FormLabel, Typography, Input, Grid, Link } from '@material-ui/core';
import Dialog from '../ui/Dialog';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay.component';
import CreateIcon from '@material-ui/icons/Create';
import { upload }  from '../../services/storage.service';
import moment from 'moment'
import DatePicker from '../DatePicker/DatePicker.component';
import { reject, find, map } from 'lodash';
import { updateTransactionCodeDetails } from '../../services/transaction.service';

const UpdateUtilizationForm = ({ data }) => {
  const { patient_id, utilization_forms, program_id, transaction_code_id } = data;
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmation, setConfirmation] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState('Successfully updated!');
  const [isLoading, setLoading] = useState(false);
  let utilizationFormInputFileRef = useRef(null);
  const currentDate = new Date();
  const [utilizationDate, setUtilizationDate] = useState(new Date());
  let defaultUtilizationRow = [{ id: `${patient_id}-0`}];
  if (utilization_forms && utilization_forms.length) {
    const utilizationFormsDefault = map(utilization_forms, (utilization_form, key) => {
      return {
        id: `${patient_id}-${key}`,
        ...utilization_form,
        webview_path: utilization_form.webviewPath,
        image_url: utilization_form.webviewPath
      }
    });

    defaultUtilizationRow = utilizationFormsDefault;
  }
  const [defaultUtilizationForm, setDefaultUtilizationForm] = useState(defaultUtilizationRow);
	const [utilizationUploadRows, setUtilizationUploadRows ] = useState(defaultUtilizationRow);

  const useStyles = makeStyles(() => ({
    field: {
      marginBottom: 20,
    },
    formControl: {
      width: '100%'
    }
  }));
  const classes = useStyles();
  
  const handleClickAction = () => {
    setOpenModal(true);
  }

  const handleCloseSubmit = () => {
    setOpenModal(false);
    setConfirmation(false);
    setErrorMessage(null);

    if (openConfirmation) {
      window.location.reload();
    }
  }

  const handleClose = () => {
    setOpenModal(false);
    setConfirmation(false);
    setErrorMessage(null);
    setDefaultUtilizationForm(utilization_forms);

    if (openConfirmation) {
      window.location.reload();
    }
  }

  const blobToFile = (imageBlob) => {
    return new File([imageBlob], imageBlob.name, {
        lastModified: moment().unix(),
        type: imageBlob.type,
    });
  }

  const removeUtilizationFormRow = async(id) => {		 
		// if(utilizationUploadRows.length > 1){
		// 	// removePhoto(id);
		// 	const utilizationUpdatedRows = reject(utilizationUploadRows, { id });
		// 	setUtilizationUploadRows(utilizationUpdatedRows);
		// }else{
    //   setErrorMessage('You can not delete all rows');
		// }
    const updatedUtilizationUpload = utilizationUploadRows.map(utilizationUploadRow => 
      utilizationUploadRow.id === id ? 
          {...utilizationUploadRow, filepath: null, type: null,
            webviewPath: null, webview_path: null, image_url: null } : utilizationUploadRow );
    setUtilizationUploadRows(updatedUtilizationUpload);
	}
 
  const onSubmit = async () => {
    try {
      setLoading(true);

      await updateTransactionCodeDetails({
        transaction_code_id,
        connect_program_id: program_id,
        payload: {
          utilization_forms: utilizationUploadRows
        }
      })

      handleCloseSubmit();
      setSuccessMessage('Utilization form uploaded!');
      setConfirmation(true);
    } catch(err) {
      setErrorMessage(err.message);
    } finally {
      setLoading(false);
    }
  }

  const utilizationFormContent = (key) => {
    const utilizationForm = find(utilizationUploadRows, { id: `${patient_id}-${key}`});
    return (
    <>
       <FormControl className={classes.formControl} component={'span'}>
        <FormLabel>
          <Typography component={'span'}>
            Utilization Form
          </Typography>
        </FormLabel>
        {
          utilizationForm && utilizationForm.webviewPath ? 
          <>
            <Grid container>
              <Grid item sm={8}>
                {
                    utilizationForm.type === 'application/pdf' ?
                    <Link href={utilizationForm.webviewPath} target='_blank'>{utilizationForm.webviewPath}</Link> : 
                    <>
                      <img width={"250px"} src={utilizationForm.webviewPath} /> 
                      <Link href={utilizationForm.webviewPath} target='_blank'>{utilizationForm.webviewPath}</Link>
                    </>
                }
              </Grid>
              <Grid item sm={4}>
                <Button color="primary" variant="contained" onClick={()=> {
                  removeUtilizationFormRow(`${patient_id}-${key}`)
                }}>Remove File</Button>
              </Grid>
            </Grid>
          </> 
          : <></>
        }
        <Button color="primary" variant="contained" onClick={()=> { utilizationFormInputFileRef.current.click() } } id={`${patient_id}-${key}`}>Upload File</Button>
        <Input component={'span'} style={{ visibility: 'hidden' }} type="file" id="file" inputRef={utilizationFormInputFileRef} onChange={async (event) => {
          setLoading(true);
          const targetEvent = event.target;
          console.log(targetEvent);
          const targetEventFiles = event.target.files;
          const { result } = await upload(blobToFile(event.target.files[0]), program_id);

          if (result && result.url) {
            const updatedUtilizationUpload = utilizationUploadRows.map(utilizationUploadRow => 
              utilizationUploadRow.id === `${patient_id}-${key}` ? 
                  {...utilizationUploadRow, filepath: targetEventFiles[0].name, type: targetEventFiles[0].type,
                    webviewPath: result.url, webview_path: result.url, image_url: result.url } : utilizationUploadRow );
            setUtilizationUploadRows(updatedUtilizationUpload);
          }

          setLoading(false);
        }}></Input>
      </FormControl>
      <FormControl className={classes.formControl}>
        <FormLabel>
            <Typography>
              Utilization Date
            </Typography>
        </FormLabel>
        <DatePicker
          required={true}
          onChange={(e) => {
            const updatedUtilizationUpload = utilizationUploadRows.map(utilizationUploadRow => 
              utilizationUploadRow.id === `${patient_id}-${key}` ? 
                  {...utilizationUploadRow, utilization_date: e } : utilizationUploadRow );
            setUtilizationUploadRows(updatedUtilizationUpload);
          }}
          name='utilization_date'
          className={classes.field} 
          maxDate={currentDate}
          defaultValue={currentDate}
          value={utilizationForm ? utilizationForm.utilization_date : currentDate}
        />
      </FormControl>
    </>
    )
  }

  const formContent = (
    <>
      	{ utilizationUploadRows && 
				utilizationUploadRows.map((value, key) => {

				return (
					utilizationFormContent(key)
				)})
			}
      <Button variant='contained' onClick={() => setUtilizationUploadRows([...utilizationUploadRows, { id: `${patient_id}-${utilizationUploadRows.length}`}])}>Add+</Button>
      <Typography
          variant='caption'
          display='block'
          gutterBottom
          color='error'
      >
          { errorMessage }
      </Typography>
    </>
  );

  const formButtons = (
    <>
        { openConfirmation ? 
        <Button onClick={handleClose} variant='contained'>
            OK
        </Button>
        : 
        <>
          <Button onClick={handleClose} variant='contained'>
            Close
          </Button>
          <Button onClick={onSubmit} variant='contained' color='primary'>
            Submit
          </Button> 
        </>
        }
    </>
  );

  if (isLoading) {
    return <LoadingOverlay />;
  }

  return (
    <>
     <Dialog 
      key='upload-utilization-form'
      open={openModal} 
      title = {modalTitle}
      content = {formContent}
      children = {formButtons}
      onSubmit={onSubmit}
      onClose={handleClose}
      />
     <Dialog 
      key='confirmation'
      open={openConfirmation}
      title = 'Confirmation'
      content = {successMessage}
      children = {formButtons}
      onClose={handleClose}
      />
      <Button variant="contained" color="primary" onClick={() => handleClickAction()} style={{ marginRight: 8 }}>
        Upload
      </Button>
    </>
  )
};

export default UpdateUtilizationForm;